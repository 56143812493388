import React, { memo } from 'react';
import PropTypes from 'prop-types';
import makeAsyncScriptLoader from 'react-async-script';
import { formPublishUrl } from 'api/index';

const FaScript = memo(function FaScript(props) {
  const { faid, asyncScriptOnLoad } = props;

  const AsyncScriptComponent = makeAsyncScriptLoader(formPublishUrl + faid, {
    attributes: {
      'data-qp-target-id': 'fa-wrapper'
    },
  })(() => <></>);

  return (
    <AsyncScriptComponent
      asyncScriptOnLoad={asyncScriptOnLoad}
    />
  );
}, () => true);

FaScript.propTypes = {
  faid: PropTypes.string,
  asyncScriptOnLoad: PropTypes.func,
};

export default FaScript;
