import axios from 'axios';
import { api, formPublishUrl } from './env-api';

let { baseUrl } = api;

const getMainData = (alias = 'sg') => {
  const url = `${baseUrl}/pages`;

  if (alias === '' || alias === '/index.html') {
    alias = '/sg';
  }
  return axios.get(url, {
    params: {
      alias
    }
  });
};

export { getMainData, formPublishUrl };
