import React from 'react';
import 'assets/scss/index.scss';
import Layout from 'containers/layout';

class App extends React.Component {
  render () {
    return (
      <Layout />
    );
  }
}

export default App;
