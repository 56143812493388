import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/loading';
import FaScript from './FaScript';

function QuickPublishFA (props) {
  const { faid } = props;
  const [showLoading, setShowLoading] = React.useState(true);

  const _dispatchDOMContent = () => {
    const e = new Event('DOMContentLoaded');
    window.dispatchEvent(e);
    FixUnavailableMessage();
    setShowLoading(false);
  };

  /**
   * [WORKAROUND] Fix FA quick publish unavailable message show in head tag
   * bug. Follow up email title: "[EXTERNAL] FormAssembly Help: Unavailable
   * message is not showing with Quick Publish Form Publishing"
   */
  const FixUnavailableMessage = () => {
    const $wContainer = document.querySelector('.wFormContainer');
    const $head = document.querySelector('head');
    if ($wContainer.parentNode === $head) {
      document.getElementById('FA__QP__BODYWRAPPERDIV').prepend($wContainer);
    }
  };

  return (
    <div className="form-container">
      <div className="inner-form">
        <div id="fa-wrapper"></div>
      </div>
      { showLoading && <Loading /> }
      <FaScript
        asyncScriptOnLoad={_dispatchDOMContent}
        faid={faid}
      />
    </div>
  );
}

QuickPublishFA.propTypes = {
  faid: PropTypes.string,
};

export default QuickPublishFA;
