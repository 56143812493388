import { STAGE, PROD, DEV_API, STAGE_API, PROD_API,FORM_DEV_URL, FORM_URL } from './config';

const { REACT_APP_ENV } = process.env;

let api = DEV_API;
let formPublishUrl = FORM_DEV_URL;

if (REACT_APP_ENV === STAGE) {
  api = STAGE_API;
} else if (REACT_APP_ENV === PROD) {
  api = PROD_API;
  formPublishUrl = FORM_URL;
}

export { api, formPublishUrl };
