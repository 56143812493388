import React, { Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import Error from 'components/error';
import Footer from 'components/footer';
import MainSection from 'containers/main-section';
import { getMainData } from 'api';
import Loading from 'components/loading';
import './index.scss';

class Layout extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      title: '',
      headerText: '',
      body: '',
      faid: '0',
      footerContent: '',
      noData: false,
      isLoading: true
    };
  }

  async componentDidMount () {
    let { data } = await this._getMainData();
    if (!data.length) {
      this.setState({
        noData: true
      });
    } else {
      const [pageData] = data;
      // Get title text from title HTML.
      const titleReg = />(.+)</g;
      pageData.originalTitle = pageData.title;
      pageData.title = titleReg.exec(pageData.title)[1];


      this.setState({
        ...pageData,
        headerText: pageData.title,
        footerContent: pageData.field_page_footer,
        isLoading: false
      });
    }
  }

  _getMainData () {
    let alias = window.location.pathname.replace(/(\/)$/g, '');

    return getMainData(alias);
  }

  render () {
    const { isLoading, noData } = this.state;

    return (
      <DocumentTitle title={this.state.title} >
        <Fragment>
          {
            isLoading ? <Loading/> : null
          }
          <div className="layout">
            {
              noData
                ? <Error />
                : <MainSection desc={this.state.body} faid={this.state.faid} />
            }
            <Footer footerContent={this.state.footerContent} />
          </div>
        </Fragment>
      </DocumentTitle>
    );
  }
}

export default Layout;
