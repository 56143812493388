import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

function Footer (props) {
  return (
    <div className="footer">
      <div className="footer-inner" dangerouslySetInnerHTML={{ __html: props.footerContent }} />
    </div>
  );
}

Footer.propTypes = {
  footerContent: PropTypes.string
};

export default Footer;
