export const DEV = 'dev';
export const STAGE = 'stage';
export const PROD = 'prod';
export const FORM_DEV_URL= 'https://kenvuedev.tfaforms.net/publish/';
export const FORM_URL= 'https://kenvue.tfaforms.net/publish/';

export const DEV_API = {
  baseUrl: 'https://fye2qday4g.execute-api.ap-southeast-1.amazonaws.com/dev'
};

export const STAGE_API = {
  baseUrl: 'https://caringeveryday-asia-test.kenvue.com/stage'
};

export const PROD_API = {
  baseUrl: 'https://www.caringeveryday.asia/prod'
};
