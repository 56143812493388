import React from 'react';
import './index.css';

export default function Error () {
  return (
    <div className="error">
      <h1>Page not found</h1>
    </div>
  );
}
