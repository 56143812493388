import React from 'react';
import PropTypes from 'prop-types';
import QuickPublishFA from 'containers/quick-publish-fa';
import './index.scss';

function MainSection (props) {
  return (
    <div className="main-section">
      {
        props.desc ?
          (
            <div className="desc" dangerouslySetInnerHTML={{__html: props.desc}} />
          ) :
          null
      }
      <div className="form-container">
        {props.faid !== '0' ? <QuickPublishFA faid={props.faid} /> : null}
      </div>
    </div>
  );
}

MainSection.propTypes = {
  desc: PropTypes.string,
  faid: PropTypes.string
};

export default MainSection;
